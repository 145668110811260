import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BlockedDateMasterPojo } from 'src/app/pojo/blocked-date-master.pojo';
import { TicketMasterPojo, TicketSlotDetailsPojo } from 'src/app/pojo/ticket-master.pojo';
import { LoggedUserDetailsPojo } from 'src/app/pojo/logged-user-details-pojo';
import { BlockedMasterService } from '../../Services/blocked-master.service';
import { TicketMasterService } from '../../Services/ticket-master.service';
import { UtilsFunction } from 'src/app/Utils/utils-function';
import { TicketBookingLimitationService } from 'src/app/Online-Booking/Services/ticket-booking-limitation.service';
import { TicketBookingLimitationsPojo } from 'src/app/pojo/ticket-booking-limitations.pojo';
import { RoomRegionService } from 'src/app/Online-Booking/Services/room-region.service';
import { RoomRegionPojo } from 'src/app/Online-Booking/pojo/RoomRegion.pojo';
import { RoomTypeMasterPojo } from 'src/app/Online-Booking/pojo/room-type-master.pojo';
import { DummyRoomTypeRoomPojo } from 'src/app/receipt/receipt-pojo/DummyRoomType.pojo';
import { RoomTypeMasterService } from 'src/app/Online-Booking/Services/room-type-master.service';
import { BookingLimitationsPojo } from 'src/app/pojo/Booking-limitations.pojo';

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-blocked-master',
    templateUrl: './blocked-master.component.html',
    styleUrls: ['./blocked-master.component.scss']
})

export class BlockedMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    blockedDateMasterList: BlockedDateMasterPojo[] = [];
    addBlockedDateMaster = new BlockedDateMasterPojo();
    editBlockedDateMaster = new BlockedDateMasterPojo();
    bookingLimitationList: BookingLimitationsPojo[] = [];

    roomTypeMasterList: RoomTypeMasterPojo[] = [];

    roomTypeList:DummyRoomTypeRoomPojo[]=[];

    ticketMasterList: TicketMasterPojo[] = [];
    slotList: TicketSlotDetailsPojo[] = [];
    roomregionList: RoomRegionPojo[]=[];


    id: number;

    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    addStartDate: NgbDate;
    addToDate: NgbDate;
    editStartDate: NgbDate;
    editToDate: NgbDate;

    isLoading: boolean;
    addLoading: boolean;
    editLoading: boolean;
    deleteLoading: boolean;

    constructor(private roomregionservice:RoomRegionService,private roomTypeMasterService:RoomTypeMasterService,private ticketBookingLimitationService: TicketBookingLimitationService,private blockedMasterService: BlockedMasterService, private calendar: NgbCalendar, private ticketMasterService: TicketMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
        this.getOnlineTicket();
        this.getAllActiveBookingDateRange();
        this.getAllRoomRegion();
        this.getAllRoomType();
        this.addStartDate = this.calendar.getToday();
        this.addToDate = this.calendar.getToday();

    }


    RegionChange(arg:number){

        this.roomregionservice.getRoomTypeMasterRegionId(arg).subscribe(response=>{
            this.roomTypeList = response;
        },error=>{
            bootbox.alert('Error!' + error.stauts);
        });
    }

    getAllRoomRegion(){
        this.isLoading = true;
        this.roomregionservice.getAllRoomRegion().subscribe( response =>{
            this.roomregionList = response;
            this.isLoading = false;
        },error=>{
          bootbox.alert({message:'get all room region error'+error.status,className:'text-danger h4 text-center '});
          this.isLoading = false;
        })
    }


  getAllRoomType(){
    this.isLoading = true;
    this.roomTypeMasterService.getAllRoomTypeMasterAll().subscribe(response=>{
      this.isLoading = false;
      this.roomTypeMasterList = response;
    },error=>{
      this.isLoading =false;
      bootbox.alert({ message: 'room Type Master All Error' + error.status, className: 'h5 text-center text-danger', closeButton: false });
    });
  }


    getAllActiveBookingDateRange() {
        this.ticketBookingLimitationService.getBookingLimitation().subscribe(reponse => {
            this.bookingLimitationList = reponse;
        }, error => {
            bootbox.alert({ message: 'Service Error! HTTP Status ' + error.status, className: 'text-center h5 text-danger', closeButton: false });
            this.isLoading = false;
        });
    }


    getServicetypeNameById(arg:number){
        if(this.bookingLimitationList.find(r=>r.bookingServiceTypeId == arg)){
           return this.bookingLimitationList.find( r=> r.bookingServiceTypeId == arg).bookingServiceType;

        }else {
            return arg;
        }
    }

    getOnlineTicket() {
        this.isLoading = true;
        this.ticketMasterService.getTicketAll().subscribe(response => {
            this.isLoading = false;
            this.ticketMasterList = response;
            this.getAllBlockedDate();
        }, error => {
            bootbox.alert({ message: 'Ticket Master All Error! HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false });
            this.isLoading = false;
        });
    }


    getAllBlockedDate() {
        this.isLoading = true;
        this.blockedMasterService.blockedDateMasterAll().subscribe(response => {
            this.isLoading = false;
            this.blockedDateMasterList = response;
        }, error => {
            bootbox.alert('Error' + error.status);
            this.isLoading = false;
        });
    }

    getEServiceName(eServiceId: number) {
        let serviceType: string;
        if (eServiceId == 5) {
            serviceType = 'TICKET BOOKING';
        } else if (eServiceId == 4) {
            serviceType = 'ROOM BOOKING';
        }
        return serviceType;
    }

    getType1(eServiceId: number, typeId: number) {
        let type: string;
        if (eServiceId == 5) {
            type = this.ticketMasterList.find(res => res.ticketMasterId == typeId).ticketName;
        }
        else if (eServiceId == 4) {
            type = this.roomregionList.find(res => res.roomRegionId == typeId).regionName;
        }
        return type;
    }

    getType2(eServiceId: number, typeId: number, typeId2: number) {
        let type: string;
        if (eServiceId == 5) {
            if (typeId != 0) {
                let i = this.ticketMasterList.findIndex(res => res.ticketMasterId == typeId);
                if (typeId2 != 0) {
                    type = this.ticketMasterList[i].slotList.find(res => res.slotId == typeId2).slotName;
                }
            }
        }
        else if (eServiceId == 4) {
            // if (typeId != 0) {
            //     let i = this.roomregionList.findIndex(res => res.roomRegionId == typeId);
            //     if (typeId2 != 0) {
            //         type = this.roomTypeMasterList.find(res => res.roomTypeId == typeId2).roomType;
            //     }
            // }
                                type = this.roomTypeMasterList.find(res => res.roomTypeId == typeId2).roomType;


        }
        return type;
    }

    ticketChange(arg: number) {
        let i = this.ticketMasterList.findIndex(res => res.ticketMasterId == arg);
        this.slotList = this.ticketMasterList[i].slotList;
    }



    createBlockedDate() {
        this.addLoading = true;
        this.addBlockedDateMaster.eServiceType = this.getEServiceName(this.addBlockedDateMaster.eServiceId);
        this.addBlockedDateMaster.blockFrom = UtilsFunction.getDateByNgbDate(this.addStartDate);
        this.addBlockedDateMaster.blockTo = UtilsFunction.getDateByNgbDate(this.addToDate);

        this.addBlockedDateMaster.typeId2 = this.addBlockedDateMaster.typeId == 0 ? 0 : this.addBlockedDateMaster.typeId2;
        this.addBlockedDateMaster.loggedUserDetails.loggedUserId = this.loggedUser.userId;
        this.addBlockedDateMaster.loggedUserDetails.name = this.loggedUser.name;
        this.addBlockedDateMaster.loggedUserDetails.userName = this.loggedUser.userName;
        this.blockedMasterService.addBlockedDateMaster(this.addBlockedDateMaster).subscribe(response => {
            this.addLoading = false;
            bootbox.alert('Inserted');
            (<HTMLFormElement>document.getElementById('createForm')).reset();
            this.addBlockedDateMaster = new BlockedDateMasterPojo();
            $('#addModal').modal('hide');
            this.getAllBlockedDate();
        }, error => {
            this.addLoading = false;
            bootbox.alert('Error!' + error.status);
        });
    }

    editClick(arg: BlockedDateMasterPojo) {
        this.editBlockedDateMaster = Object.assign({}, arg);
        alert(JSON.stringify(this.editBlockedDateMaster));
        this.ticketChange(this.editBlockedDateMaster.typeId2);
        // this.getType1(this.editBlockedDateMaster.eServiceId, this.editBlockedDateMaster.typeId);
        // this.getType2(this.editBlockedDateMaster.eServiceId, this.editBlockedDateMaster.typeId, this.editBlockedDateMaster.typeId2);
        this.editStartDate = UtilsFunction.getNgbDateByDateObject(this.editBlockedDateMaster.blockFrom);
        this.editToDate = UtilsFunction.getNgbDateByDateObject(this.editBlockedDateMaster.blockTo);

    }

    updateBlockedDate() {
        this.editLoading = true;
        this.editBlockedDateMaster.blockFrom = UtilsFunction.getDateByNgbDate(this.editStartDate);
        this.editBlockedDateMaster.blockTo = UtilsFunction.getDateByNgbDate(this.editToDate);
        this.editBlockedDateMaster.loggedUserDetails = new LoggedUserDetailsPojo();
        this.editBlockedDateMaster.typeId2 = this.editBlockedDateMaster.typeId == 0 ? 0 : this.editBlockedDateMaster.typeId2;
        this.editBlockedDateMaster.loggedUserDetails.loggedUserId = this.loggedUser.userId;
        this.editBlockedDateMaster.loggedUserDetails.name = this.loggedUser.name;
        this.editBlockedDateMaster.loggedUserDetails.userName = this.loggedUser.userName;
        this.blockedMasterService.blockedDateMasterUpdate(this.editBlockedDateMaster).subscribe(response => {
            this.editLoading = false;
            bootbox.alert('Updated');
            $('#editModal').modal('hide');
            this.getAllBlockedDate();
        }, error => {
            this.editLoading = false;
            bootbox.alert('Error!' + error.stauts);
        });
    }

    deleteClick(arg: number) {
        this.id = arg;
    }

    deleteConfirm() {
        $('#deleteModal').modal('hide');
        this.deleteLoading = true;
        this.blockedMasterService.blockedDateMasterDelete(this.id).subscribe(response => {
            this.deleteLoading = false;
            if (response == 0) {
                bootbox.alert('Already deleted');
            } else {
                bootbox.alert('Deleted');
            }
            this.getAllBlockedDate();
        }, error => {
            this.deleteLoading = false;
            bootbox.alert('Error' + error.status);
        });
    }
}
