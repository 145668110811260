import { CheckPointMasterPojo } from "src/app/pojo/checkpoint-master-pojo";
import { AbstractTicketSlotDetailsPojo } from "src/app/pojo/abstractslotdetail-pojo";
import { ResponsePojo } from './response.pojo';

export class TicketMasterPojo {

    ticketMasterId: number;
    ticketName: string;
    rate: number;
    ticketPerPerson: number;
    ticketPrefix: string;
    userId: number;
    code: number;
    res: string;
    tcode: string;
    verification: string;
    festivalFlag: string;
    slotFlag: string;
    receiptType: string;
    ticketGroupId: number;
    festivalAmount: number;
    normalAmount: number;

    checkPointList: TicketCheckPointPojo[];
    slotList: TicketSlotDetailsPojo[];
    absPojo: AbstractTicketSlotDetailsPojo[];

    ticketType: string;
    ticketPageType: string;
    templeId: number;
    description: string;
    status: string;
    extraPersonFlag: boolean;

    response = new ResponsePojo();

}

export class TicketCheckPointPojo {

    ticketCheckId: number;
    ticketId: number;
    ticketName: string;
    checkPointId: number;
    checkPoint: string;
}
export class TicketSlotDetailsPojo {

    slotId: number;
    ticketMasterId: number;
    slotName: string;
    fromTime: string;
    toTime: string;
    noOfTicketsPerDay: number;
    count: number;

}
